<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" v-if="$root.acceso('DEV')" >
        <v-btn
          block
          x-large
          color="secondary"
          outlined
          class="d-flex pa-4 text-center align-center justify-center text-button transition"
        >
          <v-icon left >mdi-file-sign</v-icon>
          ENVIAR CONTRATO DIGITAL
        </v-btn>
      </v-col>
      <v-col cols="12" md="3"
        ><EstadoBtn
          :estado="estudio.estado_actual || {}"
          :interno="0"
          :idEstudio="estudio.idEstudio"
          :fechaCambio="
            (estudio.cronograma.find((e) => e.interno == 0) || {}).fechaCambio
          "
          @reload="$emit('reload')"
      /></v-col>
      <!-- <v-col cols="12" md="3">
      <EstadoBtn
          :estado="estudio.estado_actual_int || {}"
          :interno="1"
          :idEstudio="estudio.idEstudio"
          :fechaCambio="
            (estudio.cronograma.find((e) => e.interno == 1) || {}).fechaCambio
          "
          @reload="$emit('reload')"/>
      </v-col> -->

      <v-col cols="12" md="2">
        <SituacionBtn
          text="Urgente"
          color="error darken-1"
          :value="estudio.urgente"
          @change="(v) => patchEstudio('urgente', v)"
        />
      </v-col>

      <v-col cols="12" md="2">
        <!-- <v-btn
            color="warning"
            :outlined="Boolean(estudio.fav)"
            rounded
            @click.stop="toggleFav"
          >
            <v-icon left>mdi-star</v-icon>
            {{ estudio.fav ? "Eliminar de" : "Añadir a " }} Favoritos
          </v-btn> -->
        <SituacionBtn
          text="Favoritos"
          color="warning"
          :value="Boolean(estudio.fav)"
          @change="toggleFav"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="3">
        <GestionTarjeta
          title="Tipo estudio"
          :value="estudio.tipo.nombreTipo"
          :icon="estudio.tipo.icon"
          prevent-default-editing
          :editing="cambios.tipoEstudio"
          @editing="(v) => (cambios.tipoEstudio = v)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <GestionTarjeta
          title="Comercial"
          :value="
            [estudio.comercial, estudio.subComercial]
              .filter((x) => x)
              .join(' - ')
          "
          icon="mdi-account-circle"
          prevent-default-editing
          :editing="cambios.comercial"
          @editing="(v) => (cambios.comercial = v)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <GestionTarjeta
          title="Instaladora asignada"
          :value="estudio.instaladora?.nombreInstaladora"
          icon="mdi-wrench"
          prevent-default-editing
          :editing="cambios.instaladora"
          @editing="(v) => (cambios.instaladora = v)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <GestionTarjeta
          title="Nivel de Interés"
          :value="estudio?.interes?.nombre"
          icon="mdi-emoticon-happy-outline"
          prevent-default-editing
          :editing="cambios.interes"
          @editing="(v) => (cambios.interes = v)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <GestionTarjeta
          title="Incidencias"
          icon="mdi-alert-circle"
          prevent-default-editing
          :editing="cambios.incidencias"
          @editing="(v) => (cambios.incidencias = v)"
        />
      </v-col>
    </v-row>

    <v-row>
      <!-- <v-col cols="12" sm="4" md="2">
        <GestionTarjeta
          title="Interés cliente"
          :value="estudio.interes"
          icon="mdi-emoticon-happy-outline"
          @change="(v) => patchEstudio('interes', v)"
          :editing="cambios.interes"
          @editing="(v) => (cambios.interes = v)"
        />
      </v-col> -->
      <v-col cols="12" sm="4" md="2">
        <GestionTarjeta
          title="Inclinación"
          :value="estudio.inclinacion"
          icon="mdi-slope-downhill"
          @change="(v) => patchEstudio('inclinacion', v)"
          :editing="cambios.inclinacion"
          @editing="(v) => (cambios.inclinacion = v)"
        />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <GestionTarjeta
          title="Azimut"
          :value="estudio.azimut"
          icon="mdi-rotate-360"
          @change="(v) => patchEstudio('azimut', v)"
          :editing="cambios.azimut"
          @editing="(v) => (cambios.azimut = v)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <AutoEstudios
          @reload="$emit('reload')"
          :idEstudio="idEstudio"
          :estudio="estudio"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="cambios.comercial" max-width="700">
      <CambiarComercial
        @close="cambios.comercial = false"
        @reload="$emit('reload')"
        :estudio="idEstudio"
      />
    </v-dialog>

    <v-dialog v-model="cambios.interes" max-width="700">
      <InteresEstudio
        @reload="$emit('reload')"
        @close="cambios.interes = false"
        v-model="estudio.interes"
        :estudio="idEstudio"
      />
    </v-dialog>

    <v-dialog v-model="cambios.tipoEstudio" max-width="700">
      <CambiarTipo
        @close="cambios.tipoEstudio = false"
        @reload="$emit('reload')"
        :estudio="idEstudio"
      />
    </v-dialog>

    <v-dialog v-model="cambios.instaladora" max-width="700">
      <InstaladorasEstudios
        @close="cambios.instaladora = false"
        @reload="$emit('reload')"
        :estudio="idEstudio"
      />
    </v-dialog>

    <v-dialog v-model="cambios.incidencias" max-width="700">
      <InstaladorasEstudios
        @close="cambios.incidencias = false"
        @reload="$emit('reload')"
        :estudio="idEstudio"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import {
  downloadFile,
  parseDate,
  getSituacionIcon,
  timeAgo,
  jsonToCsv,
  copyText,
} from "@/utils/index.js";

export default {
  props: {
    estudio: Object,
    idEstudio: Number,
  },
  components: {
    GestionTarjeta: () => import("./GestionTarjeta.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    EstadoBtn: () => import("../EstadoBtn.vue"),
    SituacionBtn: () => import("../SituacionBtn.vue"),
    InstaladorasEstudios: () => import("../InstaladorasEstudios.vue"),
    AutoEstudios: () => import("../AutoEstudios.vue"),

    CambiarComercial: () => import("../CambiarComercial.vue"),
    InteresEstudio: () => import("../InteresEstudio.vue"),
    CambiarTipo: () => import("../CambiarTipo.vue"),

    DocumentacionEstudio: () =>
      import("@/modules/estudios/components/DocumentacionEstudio.vue"),
    CronogramaEstudio: () => import("@/components/CronogramaEstudio.vue"),
    CrearAlerta: () => import("@/components/CrearAlerta.vue"),
    Chat: () => import("../Chat/Chat.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      filterNotas: null,
      creatingAutoestudio: false,

      cambios: {
        comercial: false,
        tipoEstudio: false,
        potenciaPico: false,
        precioPico: false,
        precioTotal: false,
        inclinacion: false,
        azimut: false,
        interes: false,
        instaladora: false,
        incidencias: false,
      },
    };
  },
  methods: {
    parseDate,
    getSituacionIcon,
    timeAgo,
    copyText,
    async descargarCronograma() {
      const csvHeaders = [
        { key: "usuario" },
        { key: "apagado" },
        { key: "enviado" },
        { key: "fecha", dataType: "date" },
        { key: "resumen" },
        { key: "texto" },
        { key: "tipo" },
      ];

      jsonToCsv(
        this.cronograma.notas,
        csvHeaders,
        `cronograma_estudio_${this.estudio.idEstudio}_${Date.now()}.csv`
      );
    },
    async patchEstudio(key, val) {
      await axios({
        url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
        method: "PATCH",
        data: {
          [key]: val,
        },
      });

      this.$emit("reload");
    },
    async toggleFav() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/favoritos`,
        data: {
          idEstudio: this.estudio.idEstudio,
        },
      });
      this.$emit("reload");
      this.$root.$emit(
        "snack",
        `Estudio ${this.estudio.idEstudio} ${
          this.estudio.fav ? "eliminado de" : "añadido a"
        } favoritos`
      );
      this.$root.$emit("getFav");
    },
  },
};
</script>

<style></style>
